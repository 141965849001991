<template>
  <main>
    <div class="mb-8">
      <p class="font-cooper text-brand-black font-normal lg:text-4xl text-2xl">
        Welcome onboard, {{ userDetails.name.split(' ').slice(0, 1).toString() }}.
      </p>
      <p class="text-brand-black/40 text-base font-normal">Let’s get you set up.</p>
    </div>
    <form v-if="pageLoaded">
      <div class="grid gap-x-5 md:grid-cols-2 mb-6">
        <Select label="Country" labelFor="Country" id="Country" :options="country" v-model="profileDetails.country" />
        <Select label="State" labelFor="State" id="State" :options="state" v-model="profileDetails.state" />
      </div>
      <div class="mb-6">
        <Input
          label="Role title"
          labelFor="RoleTitle"
          type="text"
          id="role_title"
          placeholder="e.g Frontend Developer"
          v-model="profileDetails.role_title"
        />
      </div>
      <div class="mb-6">
        <Select label="Job Category" labelFor="JobCategory" id="job_category" :options="jobTitle" v-model="profileDetails.professional_title" />
      </div>
      <div class="mb-6">
        <Select
          label="Highest Academic Qualification"
          labelFor="Qualification"
          id="Qualification"
          :options="enums.candidate_academic_qualifications"
          v-model="profileDetails.academic_qualification"
        />
      </div>
      <div class="mb-10">
        <Input
          label="LinkedIn Profile"
          labelFor="linkedInProfile"
          type="url"
          id="linkedInProfile"
          placeholder="https://linkedin/in/username..."
          v-model="profileDetails.social_links.linkedin"
        />
      </div>
    </form>
  </main>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import Select from '@/components/form/Select.vue';
import Input from '@/components/form/Input.vue';
import { errorMessage } from '@/utils/helper';

const store = useStore();
const enums = ref(null);
const country = ref(null);
const state = ref(null);
const pageLoaded = ref(false);
const jobTitle = ref(null);
const profileDetails = ref({
  country: '',
  state: '',
  professional_title: '',
  role_title: '',
  academic_qualification: '',
  social_links: {
    linkedin: '',
  },
});
onMounted(async () => {
  getSetupData();
});
watch(
  () => profileDetails.value.country,
  async (country) => {
    try {
      state.value = await store.dispatch('global/getStates', country);
    } catch (error) {
      toast.error(errorMessage(error), {
        timeout: 3000,
        hideProgressBar: true,
      });
    }
  },
);

const userDetails = computed(() => {
  return store.state.auth.userDetails;
});

async function getSetupData() {
  try {
    enums.value = await store.dispatch('global/getEnums');
    country.value = await store.dispatch('global/getCountries');
    jobTitle.value = await store.dispatch('global/getJobCategories');
    if (profileDetails.value.country) {
      state.value = await store.dispatch('global/getStates', profileDetails.value.country);
    }
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    pageLoaded.value = true;
  }
}

defineExpose({
  profileDetails,
});
</script>
